import { Deserializable } from './deserialize';
import { IOriginalArticlePrices } from "./articleOption";
import {IContingent} from "./contingent";

export interface IArticle {
  alwaysInStock?: boolean;
  alwaysOnStock?: boolean;
  token?: number;
  optionText?: string;
  additionalOptions?: any;
  selectedAdditionalOptions?: any;
  deliveryTime?: number;
  characterCount?: number;
  selected?: boolean;
  category?: string[];
  connectId: number;
  articleId?:number,
  quantity?: number;
  stateQuantity?: number;
  createdAt?: string;
  deletedAt?: string;
  description?: string;
  imageDate: string;
  amount?: number;
  id: string;
  _id?: string;
  imageSourceUrl?: string;
  mainImage?: string;
  contingent?:IContingent;
  name?: string;
  title?: string;
  previewUrl?: string;
  packagingAmount?: number;
  price?: number;
  images?: string[];
  customerPrice?: IOriginalArticlePrices[];
  industryPrice?: IOriginalArticlePrices[];
  stock?: number;
  artikelText?: {
    "de": {
      "name": string,
      "description": string
    }
  };
  totalPrice?: number;
  totalAmount?: number;
  type?: string;
  options?: any;
  selectedOptions?: any;
  updatedAt?: string;
  hasOptionSelected?: boolean;
  minimumOrderQuantity?: number;
  quantityGrouping?: boolean;
  virtual?: boolean;
  selectedOption?: any;
  value?: number;
  vat?: number;
  collection?: any;
  fileStackId?: string;
  summarizeOptions?: any;
}

export class Article implements IArticle, Deserializable {
  alwaysInStock?: boolean
  alwaysOnStock?: boolean;
  token?: number;
  optionText?: string;
  additionalOptions?: any;
  selectedAdditionalOptions?: any;
  deliveryTime?: number;
  characterCount?: number;
  selected?: boolean;
  category?: string[];
  connectId: number;
  articleId?:number;
  quantity?: number;
  createdAt?: string;
  deletedAt?: string;
  description?: string;
  imageDate: string;
  amount?: number;
  id: string;
  _id?: string;
  imageSourceUrl?: string;
  mainImage?: string;
  contingent?:IContingent;
  name?: string;
  title?: string;
  previewUrl?: string;
  packagingAmount?: number;
  price?: number;
  images?: string[];
  customerPrice?: IOriginalArticlePrices[];
  industryPrice?: IOriginalArticlePrices[];
  stock?: number;
  artikelText?: {
    "de": {
      "name": string,
      "description": string
    }
  };
  totalPrice?: number;
  totalAmount?: number;
  type?: string;
  options?: any;
  selectedOptions?: any;
  updatedAt?: string;
  hasOptionSelected?: boolean;
  minimumOrderQuantity?: number;
  quantityGrouping?: boolean;
  virtual?: boolean;
  selectedOption?: any;
  value?: number;
  vat?: number;
  collection?: any;
  fileStackId?: string;
  summarizeOptions?: any;
  deserializeInput(input: any) {
    this.alwaysOnStock = Boolean(input.alwaysOnStock);
    this.description = String(input.description);
    this.name = String(input.name);
    this.price = Number(input.price);
    this.stock = Number(input.stock);
    this.previewUrl = String(input.previewUrl);
    this.vat = Number(input.vat);

    this.customerPrice = input.customerPrice?.map(cp => ({
      scale: Number(cp.scale),
      price: Number(cp.price)
    }));
    this.artikelText = input.artikelText; // Handle complex objects if necessary

    this.category = input.category.map(String);  // Assuming all categories are string IDs
    this.type = String(input.type);
    this.packagingAmount = Number(input.packagingAmount);
    this.quantityGrouping = Boolean(input.quantityGrouping);
    this.deliveryTime = Number(input.deliveryTime);
    this.minimumOrderQuantity = Number(input.minimumOrderQuantity);
    this.characterCount = Number(input.characterCount);
    this.images = input.images.map(String);
    this.options = input.options; // Handle complex objects if necessary
    return this;
  }
  deserialize(input: IArticle) {
    Object.assign(this, input);
    this.virtual = (input.type == "Virtuell");
    this.hasOptionSelected = (!input?.options);
    this.id = input.id;
    return this;
  }
}
